/// <reference types="@angular/localize" />

import { bootstrapApplication } from "@angular/platform-browser";
import { provideRouter, withComponentInputBinding, withRouterConfig, withViewTransitions } from "@angular/router";
import { HttpClient, provideHttpClient, withInterceptors } from "@angular/common/http";
import { AppComponent } from "./app/app.component";
import { APP_ROUTES } from "./app/routes";

import { loggerInterceptor } from "./app/networking/interceptors/logging";
import { authInterceptor } from "./app/networking/interceptors/auth";
import { responseToCamelCaseInterceptor } from "./app/networking/interceptors/response-to-camel-case";
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';

import { environment } from './environments/environment';
import { ROOT_REDUCERS, metaReducers } from './app/state'


import { provideTranslateService, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { languageInterceptor } from "./app/networking/interceptors/language";

const httpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, '/i18n/', '.json');


bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(APP_ROUTES,
      withViewTransitions(),
      withComponentInputBinding(),
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
      })
    ),
    provideHttpClient(withInterceptors([
        loggerInterceptor,
        authInterceptor,
        languageInterceptor,
        responseToCamelCaseInterceptor
    ])),
    provideAnimations(),
    provideStore(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    provideEffects(),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: environment.production,
      connectInZone: true
    }),
    provideTranslateService({
      defaultLanguage: 'pl',
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
})
