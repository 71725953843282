import { Injectable } from "@angular/core";

@Injectable({
	providedIn: 'root'
})
export class ThemeService {
	toggleDark() {
		const body = document.getElementsByTagName('body')[0];
		body.classList.remove('dark-mode');
		body.classList.add('dark-mode');
	}

	toggleLight() {
		const body = document.getElementsByTagName('body')[0];
		body.classList.remove('dark-mode');
	}
}
