import { HttpInterceptorFn, HttpRequest, HttpHandlerFn, HttpEvent } from "@angular/common/http";
import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { LocalStorageService } from "src/app/local-storage.service";

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {

    const localStorage = inject(LocalStorageService);
    const authToken = localStorage.get(LocalStorageService.authToken);

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const modifiedRequest = req.clone({
        headers: req.headers.set('Authorization', `Token ${authToken}`)
    });

    // send cloned request with header to the next handler.
    return next(modifiedRequest);
}