import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType, OnInitEffects } from "@ngrx/effects";
import { Action } from "@ngrx/store";
import { tap } from "rxjs";

import * as ThemeActions from './theme.actions';
import { ThemeService } from "./theme.service";
import { LocalStorageService } from "../local-storage.service";

@Injectable()
export class ThemeEffects implements OnInitEffects {
    private readonly actions$ = inject(Actions);
    private readonly localStorageService = inject(LocalStorageService);
    private readonly themeService = inject(ThemeService);

    ngrxOnInitEffects(): Action {
        const theme = this.localStorageService.get(LocalStorageService.theme);
        const dark = theme === 'dark';
        return ThemeActions.toggleTheme(dark);
    }

    toggleTheme$ = createEffect(() => this.actions$.pipe(
        ofType(ThemeActions.toggleTheme),
        tap(({ dark }) => {
            if (dark) {
                this.themeService.toggleDark();
                this.localStorageService.set(LocalStorageService.theme, 'dark');
            } else {
                this.themeService.toggleLight();
                this.localStorageService.set(LocalStorageService.theme, 'light');
            }
        }),
    ), { dispatch: false });
}
