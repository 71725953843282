import {
    ActionReducer,
    ActionReducerMap,
    MetaReducer,
} from '@ngrx/store';
import { environment } from '../../environments/environment';
import * as fromAuth from '../auth/state/auth.reducer';
import * as fromTheme from '../theme/theme.reducer';
import * as fromLanguage from '../language/language.reducer';

export interface State {
    [fromAuth.featureKey]: fromAuth.State;
    [fromTheme.featureKey]: fromTheme.State;
    [fromLanguage.featureKey]: fromLanguage.State;
};

export const ROOT_REDUCERS: ActionReducerMap<State> = {
    [fromAuth.featureKey]: fromAuth.reducer,
    [fromTheme.featureKey]: fromTheme.reducer,
    [fromLanguage.featureKey]: fromLanguage.reducer,
};

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
    return (state, action) => {
        const result = reducer(state, action);
        console.groupCollapsed(action.type);
        console.log('prev state', state);
        console.log('action', action);
        console.log('next state', result);
        console.groupEnd();
    
        return result;
    };
}
export const metaReducers: MetaReducer<State>[] = !environment.production
    ? [logger]
    : [];
  