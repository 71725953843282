import { HttpInterceptorFn, HttpRequest, HttpHandlerFn, HttpEvent } from "@angular/common/http";
import { inject } from "@angular/core";
import { Observable } from "rxjs";
import { LoggerService } from "src/app/logger.service";

export const loggerInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const logger = inject(LoggerService);
    
    logger.log(`Request is on its way to ${req.url}`);
    
    return next(req);
}