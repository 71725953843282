import { HttpInterceptorFn, HttpRequest, HttpHandlerFn, HttpEvent } from "@angular/common/http";
import { inject } from "@angular/core";

import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import * as fromLanguage from "../../language/language.reducer";


export const languageInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> => {
    const store = inject(Store);
    const language = store.selectSignal(fromLanguage.language);

    if (req.method === 'GET') {
        const modifiedReq = req.clone({
            params: req.params.set('lang', language())
        });
        return next(modifiedReq);
    }

    return next(req);
}