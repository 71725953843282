import { Injectable } from '@angular/core';
import { BaseService } from './networking/services/base-service';
import { Credentials } from './auth/credentials';
import { User } from './auth/models/user';

@Injectable({
providedIn: 'root'
})
export class AuthService extends BaseService {
	me() {
		return this.http.get<User>(`${this.baseUrl}/auth/me/`);
	}

	signIn(credentials: Credentials) {
		const { email, password } = credentials;
		
		return this.http.post<User>(`${this.baseUrl}/auth/signin/`, {
			username: email, 
			password
		});
	}

	signUp(credentials: Credentials) {
		const { email, password } = credentials;
		return this.http.post<User>(`${this.baseUrl}/auth/signup/`, {
			username: email,
			email,
			password
		});
	}
}

