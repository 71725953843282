import { Component, inject } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { RouterLinkWithHref } from "@angular/router";
import { Store } from "@ngrx/store";

import { CredentialsFormComponent, CredentialsWithRememberMe } from "./credentials-form.component";
import { OAuthType } from "./credentials";
import * as AuthActions from "./state/auth.actions";
import { LogoComponent } from "../logo/logo.component";
import { TranslatePipe } from "@ngx-translate/core";
@Component({
    selector: "glotto-login",
    standalone: true,
    imports: [
        MatCardModule,
        RouterLinkWithHref,
        LogoComponent,
        CredentialsFormComponent,
        TranslatePipe,
    ],
    styles: [`
        @import 'src/styles/_mixins.scss';

        :host {
            @include flex-column-container();

            justify-content: center;
            align-items: center;
        }

        .sign-in {
            max-width: 500px;
            
            mat-card {
                background-color: var(--surface);
                border-radius: var(--rounded-corners);
            }

            mat-card-header {
                justify-content: center;
            }
        }

        .login-alternatives {
            display: flex; 
            justify-content: center;
        }

        .login-need-an-account {
            text-align: center;
        }

    `],
    template: `

    <div class="sign-in">
        <glotto-logo />
        <mat-card appearance="outlined">
            <mat-card-header>
                <mat-card-subtitle>
                    <h3>{{ 'landing.welcome' | translate }}</h3>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <glotto-credentials
                    [type]="'sign-in'"
                    (oauth)="onOAuth($event)"
                    (credentials)="onSubmit($event)"/>

                <div class="login-need-an-account">
                    <p>{{ 'landing.need_an_account' | translate }} <a style="color: inherit" routerLink="/sign-up">{{ 'landing.sign_up' | translate }}</a></p>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    `,
    
})
export class SignInComponent {
    private readonly store = inject(Store);

    public onSubmit(credentials: CredentialsWithRememberMe): void {
        this.store.dispatch(AuthActions.signIn({ credentials }));
    }

    public onOAuth(type: OAuthType): void {

    }
}
