import { Component, inject } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule} from "@angular/material/input";
import { RouterLinkWithHref } from "@angular/router";
import { Store } from "@ngrx/store";

import { CredentialsFormComponent } from "./credentials-form.component";
import { Credentials, OAuthType } from "./credentials";
import * as AuthActions from "./state/auth.actions";
import { LogoComponent } from "../logo/logo.component";
import { TranslatePipe } from "@ngx-translate/core";

@Component({
    selector: "glotto-login",
    standalone: true,
    imports: [
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        RouterLinkWithHref,

        CredentialsFormComponent,
        LogoComponent,
        TranslatePipe
    ],
   
    template: `
    <div class="sign-up">
        <glotto-logo/>
        <mat-card appearance="outlined">
            <mat-card-header>
                <mat-card-subtitle>
                    <h3>{{ 'landing.welcome_sign_up' | translate }}</h3>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <glotto-credentials 
                    [type]="'sign-up'"
                    (oauth)="onOAuth($event)"
                    (credentials)="onSubmit($event)"/>

                <div class="sign-up-have-an-account">
                    <p>{{ 'landing.already_a_user' | translate }} <a style="color: inherit" routerLink="/sign-in">{{ 'landing.sign_in' | translate }}</a></p>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    `,
     styles: [`
        @import 'src/styles/_mixins.scss';

        :host {
            @include flex-column-container();

            justify-content: center;
            align-items: center;
        }

        .sign-up {
            max-width: 500px;

            mat-card {
                background-color: var(--surface);
                border-radius: var(--rounded-corners);
            }

            mat-card-header {
                justify-content: center;
            }
        }

        

        .sign-up-have-an-account {
            text-align: center;
        }
        

    `],
})
export class SignUpComponent {
    private readonly store = inject(Store);

    public onSubmit(credentials: Credentials): void {
        this.store.dispatch(AuthActions.signUp({ credentials }));
    }

    public onOAuth(type: OAuthType): void {

    }
}
