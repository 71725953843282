import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../local-storage.service';
import * as LanguageActions from './language.actions';

@Injectable()
export class LanguageEffects implements OnInitEffects {
  private readonly actions$ = inject(Actions);
  private readonly localStorageService = inject(LocalStorageService);
  private readonly translateService = inject(TranslateService);

  initializeLanguage$ = createEffect(() => this.actions$.pipe(
    ofType(LanguageActions.initializeLanguage),
    map(() => {
      const savedLanguage = this.localStorageService.get(LocalStorageService.language);
      const browserLang = this.translateService.getBrowserLang();
      const language = savedLanguage || 
                      (browserLang && ['en', 'pl'].includes(browserLang) ? browserLang : 'en');
      
      return LanguageActions.setLanguage({ language });
    })
  ));

  setLanguage$ = createEffect(() => this.actions$.pipe(
    ofType(LanguageActions.setLanguage),
    map(({ language }) => {
      this.translateService.use(language);
      this.localStorageService.set(LocalStorageService.language, language);
    })
  ), { dispatch: false });

  ngrxOnInitEffects(): Action {
    return LanguageActions.initializeLanguage();
  }
}