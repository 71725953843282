import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  static authToken = 'GLT_AUTH_TOKEN';
  static theme = 'GLT_THEME';
  static language = 'GLT_LANGUAGE';

  set(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  get(key: string): string | null {
      return localStorage.getItem(key);
  }

  remove(key: string): void {
      localStorage.removeItem(key);
  }
}
