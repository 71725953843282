import { Component, inject, OnDestroy, OnInit, DestroyRef, computed } from "@angular/core";
import { MatCardModule } from "@angular/material/card";
import { RouterLinkWithHref } from "@angular/router";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Store } from "@ngrx/store";
import { MatSnackBarModule, MatSnackBar } from "@angular/material/snack-bar";

import { CredentialsFormComponent, CredentialsWithRememberMe } from "./credentials-form.component";
import { OAuthType } from "./credentials";
import { LogoComponent } from "../logo/logo.component";
import { TranslatePipe } from "@ngx-translate/core";
import { filter, map } from "rxjs";

import { EnvironmentService } from "../networking/services/environment.service";

import * as fromTheme from '../theme/theme.reducer';
import * as fromLanguage from '../language/language.reducer';
import * as AuthActions from "./state/auth.actions";
import * as AuthSelectors from "./state/auth.reducer";
import * as ThemeActions from '../theme/theme.actions';
import * as LanguageActions from '../language/language.actions';
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
@Component({
    selector: "glotto-login",
    standalone: true,
    imports: [
        MatCardModule,
        MatSnackBarModule,
        RouterLinkWithHref,
        LogoComponent,
        CredentialsFormComponent,
        TranslatePipe,
        MatIconModule,
        MatButtonModule,
        MatMenuModule
    ],
    styles: [`
        
        :host {
            display: flex;
            min-height: 100vh;
            background-color: color-mix(in srgb, var(--mat-app-text-color) 5%, transparent);
            overflow: hidden;            
        }

        .login-container {
            display: flex;
            width: 100%;
            overflow: hidden;
            
            @media (max-width: 768px) {
                flex-direction: column;
                overflow-y: auto;
            }
        }

        .login-left {
            flex: 1;
            background: #fff;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            background-color: var(--mat-app-background-color); 
            
            @media (max-width: 768px) {
                flex: none;
                min-height: auto;
                justify-content: center;
            }

            &__theme-toggle-and-language {
                position: absolute;
                top: 0;
                left:0 ;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 5px;
                width: 100%;
                padding: var(--padding);
            }

            &__benefits {
                @media (max-width: 768px) {
                    display: none;
                }

                h2 {
                    margin-bottom: var(--gap);
                    color: var(--mat-app-text-color);
                }

                ul {
                    list-style: none;
                    padding: var(--padding);
                    
                    li {
                        margin-bottom: 1rem;
                        font-size: 1.1rem;
                        color: var(--mat-app-text-color);
                    }
                }
            }
        }

        .login-right {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            overflow-y: auto;

            @media (max-width: 768px) {
                flex: 1;
                justify-content: center;
                min-height: 100vh;
            }


            :host-context(body.dark-mode) & {
                background-color: color-mix(in srgb, var(--mat-app-background-color) 90%, transparent);
            }
        }

        .login-form-container {
            width: 100%;
            max-width: 440px;
            padding: 2rem;

            border-radius: 12px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            margin: 1rem;
            background-color: var(--mat-app-background-color);
            color: var(--mat-app-text-color);
            @media (max-width: 768px) {
                margin: 0.5rem;
                padding: 1.5rem;
                max-height: none;
                box-shadow: none;
            }

            h1 {
                margin-bottom: 2rem;
                font-size: 1.5rem;
                text-align: center;
            }
        }

        .login-need-an-account {
            margin-top: 1.5rem;
            text-align: center;
        }

        .language-button {
			font-size: 1.2rem;
			line-height: 1;
			padding: 0 8px;
		}

		:host ::ng-deep .language-menu {
			.mat-mdc-menu-content {
				padding: 0;
			}

			.mat-mdc-menu-item {
				height: 44px;
				padding: 0 16px;
			}
		}

		.language-item {
			&:hover {
				background-color: var(--mat-menu-item-hover-state-layer-color);
			}
		}

		.language-item-content {
			display: flex;
			align-items: center;
			gap: 12px;
			height: 100%;
		}

		.flag-indicator {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.2rem;
		}
    `],
    template: `
        <div class="login-container">
            <!-- Left Column -->
            <div class="login-left">
                <div class="login-left__theme-toggle-and-language">
                    <button mat-icon-button (click)="toggleTheme()">
                        <mat-icon>{{ dark() ? 'light_mode' : 'dark_mode' }}</mat-icon>
                    </button>
                    <button mat-icon-button [matMenuTriggerFor]="language" class="language-button">
                        <span class="flag-indicator">{{ currentLanguageFlag() }}</span>
                    </button>
                    <mat-menu #language="matMenu" class="language-menu">
                        <button mat-menu-item (click)="useLanguage('pl')" class="language-item">
                            <div class="language-item-content">
                                <span class="flag-indicator">🇵🇱</span>
                                <span>Polski</span>
                            </div>
                        </button>
                        <button mat-menu-item (click)="useLanguage('en')" class="language-item">
                            <div class="language-item-content">
                                <span class="flag-indicator">🇬🇧</span>
                                <span>English</span>
                            </div>
                        </button>
                        <button mat-menu-item (click)="useLanguage('uk')" class="language-item">
                            <div class="language-item-content">
                                <span class="flag-indicator">🇺🇦</span>
                                <span>Українська</span>
                            </div>
                        </button>
                    </mat-menu>
                </div>

                
                <glotto-logo />
                
                <div class="login-left__benefits">
                    <h2>{{ 'landing.whyChooseUs' | translate }}</h2>
                    <ul>
                        <li>✓ {{ 'landing.benefit1' | translate }}</li>
                        <li>✓ {{ 'landing.benefit2' | translate }}</li>
                        <li>✓ {{ 'landing.benefit3' | translate }}</li>
                        <li>✓ {{ 'landing.benefit4' | translate }}</li>
                    </ul>
                </div>
            </div>

            <!-- Right Column -->
            <div class="login-right">
                <div class="login-form-container">
                    <h1>{{ 'landing.welcome' | translate }}</h1>
                    
                    <glotto-credentials
                        [type]="'sign-in'"
                        (oauth)="onOAuth($event)"
                        (credentials)="onSubmit($event)"/>

                    <div class="login-need-an-account">
                        <p>
                            {{ 'landing.needAnAccount' | translate }} 
                            <a style="color: inherit" routerLink="/sign-up">
                                {{ 'landing.signUp' | translate }}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class SignInComponent implements OnInit, OnDestroy {
    private readonly store = inject(Store);
    private readonly snackBar = inject(MatSnackBar);
    private readonly environment = inject(EnvironmentService);
    private readonly destroyRef = inject(DestroyRef);

    dark = this.store.selectSignal(fromTheme.theme);
    currentLanguage = this.store.selectSignal(fromLanguage.language);

    currentLanguageFlag = computed(() => {
        switch (this.currentLanguage()) {
            case 'pl':
                return '🇵🇱';
            case 'uk':
                return '🇺🇦';
            default:
                return '🇬🇧';
        }
    });
    
    ngOnInit(): void {
        this.store.select(AuthSelectors.userError).pipe(
            filter(error => !!error),
            map(error => error.message),
            takeUntilDestroyed(this.destroyRef)
        ).subscribe(error => {
            this.snackBar.open(error as string, 'Close', { duration: 3000 });
        });

        // Initialize Google OAuth
        this.initializeGoogleSignIn();
    }

    ngOnDestroy(): void {
        // Clean up Google Sign-In
        (window as any).google?.accounts.id.cancel();
    }

    toggleTheme() {
		this.store.dispatch(ThemeActions.toggleTheme(!this.dark()));
	}

	useLanguage(language: string) {
		this.store.dispatch(LanguageActions.setLanguage({ language }));
	}

    private initializeGoogleSignIn(): void {
        const clientId = this.environment.resolve().googleClientId;
        console.log('Initializing Google Sign-In with client ID:', clientId);

        try {
            (window as any).google?.accounts.id.initialize({
                client_id: clientId,
                callback: this.handleGoogleSignIn.bind(this),
                auto_select: false,
                cancel_on_tap_outside: true,
                context: 'signin',
                itp_support: true,
                error_callback: (error: any) => {
                    console.error('Google Sign-In Error:', error);
                }
            });
            console.log('Google Sign-In initialized successfully');
        } catch (error) {
            console.error('Failed to initialize Google Sign-In:', error);
        }
    }

    public onSubmit(credentials: CredentialsWithRememberMe): void {
        this.store.dispatch(AuthActions.signIn({ credentials }));
    }

    public onOAuth(type: OAuthType): void {
        if (type === 'google') {
            // (window as any).google?.accounts.id.prompt();
        }
    }

    private handleGoogleSignIn(response: any): void {
        console.log('Google Sign-In response:', response);
        if (response.credential) {
            console.log('Got credential, dispatching action');
            this.store.dispatch(AuthActions.googleSignIn({ 
                token: response.credential 
            }));
        } else {
            console.error('No credential in response:', response);
            this.snackBar.open('Google sign-in failed', 'Close', { 
                duration: 3000 
            });
        }
    }
}
