import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';  

@Component({
  selector: 'glotto-root',
  standalone: true,
  imports: [
    RouterOutlet,
  ],
  template: `
    <router-outlet />
  `,
  styles: [`
    
    :host {
      background-color: var(--mat-app-background-color);  

    }
  `]
})
export class AppComponent implements OnInit {
  private translate = inject(TranslateService);

  ngOnInit() {
    this.translate.addLangs(['en', 'pl']);
    this.translate.setDefaultLang('en');
  }
}
