import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as LanguageActions from './language.actions';
export const featureKey = 'language';

export interface State {
  language: string;
}

export const initialState: State = {
  language: 'en'
};

export const reducer = createReducer(
  initialState,
  on(LanguageActions.setLanguage, (state, { language }) => ({
    ...state,
    language
  }))
);


export const languageState = createFeatureSelector<State>(featureKey);
export const language = createSelector(languageState, (state: State) => state.language);
