import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot } from "@angular/router";
import { provideEffects } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { filter, first, map, tap } from "rxjs/operators";
import { SignInComponent } from "./auth/sign-in.component";
import { SignUpComponent } from "./auth/sign-up.component";
import * as AuthActions from "./auth/state/auth.actions";
import { AuthEffects } from "./auth/state/auth.effects";
import * as fromAuth from "./auth/state/auth.reducer";
import { ThemeEffects } from "./theme/theme.effects";
import { LanguageEffects } from "./language/language.effects";


export const authenticationGuard = () => {
    return (_1: ActivatedRouteSnapshot, _2: RouterStateSnapshot) => {
        const router = inject(Router);
        const store = inject(Store);
      
        return store
            .select(fromAuth.user)
            .pipe(
                map(user => ({ user, loaded: Object.keys(user ?? {}).length > 0 })),
                tap(({loaded}) => {
                    /**
                     * This is iportant. We need to dispatch init action only once, when the component is initialized.
                     * If we dispatch it in constructor or ngOnInit of the main punchout component, it will be 
                     * dispatched on every routing action. What we need is to make sure that the init action is
                     * triggered only once and only for selected routes.
                     */
                    if (!loaded) {
                        store.dispatch(AuthActions.auth());
                    }
                }),
                filter(({loaded}) => loaded),
                first(),
                map(({user}) => !!user),
                tap(isValid => {
                    if (!isValid) {
                        router.navigate(['user', 'not-found']);
                    }
                }),
            );
    };
};

export const APP_ROUTES: Route[] = [
    { 
        path: 'sign-in', 
        component: SignInComponent,
        providers: [
            provideEffects(AuthEffects, ThemeEffects, LanguageEffects)
        ],
    },
    { 
        path: 'sign-up', 
        component: SignUpComponent,
        providers: [
            provideEffects(AuthEffects, ThemeEffects, LanguageEffects)
        ],
    },
    {
        path: 'courses', 
        canActivate: [authenticationGuard()],
        providers: [
            provideEffects(AuthEffects, ThemeEffects, LanguageEffects)
        ],
        loadChildren: () => import('./courses/routes'),
    },
    { path: '', pathMatch: 'full', redirectTo: 'courses'},
    { path: '**', redirectTo: 'courses' },
];
