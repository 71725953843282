export interface Credentials {
    email: string;
    password: string;
}

export enum OAuthType {
    Facebook = "facebook",
    Google = "google",
    Apple = "apple",
    LinkedIn = "linkedin",
}