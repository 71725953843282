import { createReducer, on } from '@ngrx/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { User } from "../models/user";
import * as AuthActions from './auth.actions';

export const featureKey = 'auth';

export interface State {
    user: User | null;
    error: Error | null;
    loading: boolean;
}

export const initialState: State = {
    user: null,
    error: null,
    loading: false,
};

export const reducer = createReducer(
    initialState,

    on(AuthActions.authSuccess, (state, { user }) => ({
        ...state,
        user,
        error: null,
        loading: false,
    })),


    on(AuthActions.signIn, (state) => ({
        ...state,
        loading: true,
    })),
    on(AuthActions.signInSuccess, (state, { user }) => ({
        ...state,
        user,
        error: null,
        loading: false,
    })),
    on(AuthActions.signInFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false,
    })),
    on(AuthActions.signUp, (state) => ({
        ...state,
        loading: true,
    })),
    on(AuthActions.signUpSuccess, (state, { user }) => ({
        ...state,
        user,
        error: null,
        loading: false,
    })),
    on(AuthActions.signUpFailure, (state, { error }) => ({
        ...state,
        error,
        loading: false,
    })),
    on(AuthActions.signOut, (state) => ({
        ...initialState
    })),
);

export const authState = createFeatureSelector<State>(featureKey);
export const user = createSelector(authState, (state: State) => state.user);
export const userError = createSelector(authState, (state: State) => state.error);
export const userLoading = createSelector(authState, (state: State) => state.loading);
